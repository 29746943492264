import { useDebounceFn } from '@vueuse/core';
import { type RouteLocationNormalizedLoaded, useRoute } from 'vue-router';

export const useFilterParams = (routeName: string) => {
	const route = useRoute();
	const filters = ref<string>(route.fullPath.split('?')[1] || '');

	const debounce = useDebounceFn((route: RouteLocationNormalizedLoaded) => {
		filters.value = route.fullPath.split('?')[1] || '';
	}, 200);

	watch(route, (route) => {
		if (route.name !== routeName) return;
		debounce(route);
	});

	const value = computed(() => {
		const pairs = filters.value.split('&');
		return pairs.reduce(
			(acc, pair) => {
				const [key, value] = pair.split('=');
				if (!key || !value) return acc;
				acc[key] = value;
				return acc;
			},
			{} as Record<string, string>,
		);
	});
	return value;
};
